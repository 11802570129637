import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BsArrowLeftShort } from 'react-icons/bs';

const Container = styled.button`
  --color: #aaa;

  display: flex;
  cursor: pointer;
  margin-bottom: 1rem;
  background-color: transparent;
  border: 1px solid var(--color);
  color: var(--color);
  padding: 0.5rem;

  > svg {
    margin-right: 0.5rem;
    margin-top: 6px;
  }
/*
  @media (min-width: 1024px) {
    display: none;
  }*/
`;

export default function BackButton({ onClick }) {
  return (
    <Container type="button" onClick={onClick}>
      <BsArrowLeftShort />
      Back
    </Container>
  );
}

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
